












































import ProfileService from "@/services/ProfileService";
import ExceptionUtil from "@/utilities/ExceptionUtil";
import { Component, Prop, Vue } from "vue-property-decorator";
import { AlertDialogProperties } from "../shared/AlertDialogProperties";
import { ProfileProperties } from "./ProfileView.vue";

@Component({
    data: () => ({
        confirmation: null
    })
})
export default class ProfileCloseAccountView extends Vue {
    @Prop() private properties: ProfileProperties;
    @Prop() private alertDialog: AlertDialogProperties;

    private profileService = new ProfileService();

    public async confirmCloseAccount() {
        try {
            if (this.$data.confirmation === this.$t('text.close-account')) {
                await this.profileService.closeAccount();
                await this.$router.push("/");
            } else {
                let m = this.$t('message.profile-close-account.alert');
                AlertDialogProperties.info(this.alertDialog, null, m);
                this.alertDialog.visible = true;
            }
        } catch (e) {            
            if (!await ExceptionUtil.isAuthError(this.$router, e)) {
                let m = ExceptionUtil.getMessage(e);
                AlertDialogProperties.error(this.alertDialog, null, m);
                this.alertDialog.visible = true;
            }
        }
    }
}
